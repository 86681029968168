@import url('https://fonts.googleapis.com/css?family=Cairo:400,700&subset=arabic');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-body !important;

    padding-bottom: 2em;
    background-color: #fbfbfb !important;
    color: #4a4a4a;
  }
}

@layer utilities {
  .bg-striped {
    background-image: linear-gradient(
      45deg,
      hsla(0, 0%, 100%, 0.15) 25%,
      transparent 0,
      transparent 50%,
      hsla(0, 0%, 100%, 0.15) 0,
      hsla(0, 0%, 100%, 0.15) 75%,
      transparent 0,
      transparent
    );
    background-size: 40px 40px;
  }
}

@layer components {
  .line {
    height: 15px;
    border-bottom: 1px solid #d4d4d4;
  }

  .triangle {
    background: white;
    border: 1px solid #d4d4d4;
    border-width: 0px 1px 1px 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(45deg);
    position: relative;
    left: -2.5em;
    top: 5px;
    width: 20px;
    height: 20px;
  }
}

.loader:before {
  content: ' ';
}

.loader {
  width: 100%;
  height: 100%;
  background-color: rgba(206, 203, 203, 0.7);
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(206, 203, 203, 0.7);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loader .spinner div {
  background-color: rgb(54, 142, 214) !important;
}

.swal2-title {
  font-family: 'Cairo';
}
.swal2-content {
  font-family: 'Cairo';
}
.swal2-buttonswrapper {
  font-family: 'Cairo';
}

.swalinput {
  text-align: center;
}

.swal2-popup .swal2-validationerror::before {
  margin-right: auto;
  margin-left: 10px;
}
