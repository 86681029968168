.StripeElement {
  background: #f9f9f9;
  padding: 18px;
  border: 1px solid #80808026;
  border-radius: 5px;
  margin: 10px 0px;
}
.StripeElement-Error {
  font-size: 16px !important;
  margin: 10px 0 !important;
  color: #f5580f;
}

@keyframes ploading {
  0% {
    box-shadow: 0px 0px 0px 0px white;
  }
  50% {
    box-shadow: 0px 0px 50px 0px #ff510091;
  }
  100% {
    box-shadow: 0px 0px 0px 0px white;
  }
}

.action-btn {
  transition: width 0.5s 0s ease-in-out;
}

.action-btn:disabled {
  background: rgb(218, 85, 85);
  animation: ploading 2s 0s ease-in-out;
  animation-iteration-count: infinite;
  opacity: 0.75;
  font-size: 12px;
  width: 250px;
  white-space: nowrap;
}
