.page-title {
  color: #35a6dd;
  text-align: center;
}

.packages-container,
.packages-note {
  display: flex;
  text-align: center;
  color: #4d4d4d;
}
.packages-note {
  display: block;
  color: #000000;
  font-size: 18px;
  line-height: 33px;
  margin: 20px;
}
.packages-note i {
  font-size: 1.2em;
}

.package {
  width: calc(50% - 20px);
  height: 300px;
  display: inline-block;
  border: 1px solid #d4d4d4;
  background-color: white;
  border-radius: 8px;
  margin: 10px;
  font-size: 1.5em;
  padding: 8px;
}

.package-price {
  text-align: center;
  font-size: 60px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.package-price sup {
  font-size: 35%;
  top: -1.5em;
  color: #35a6dd;
  font-weight: bold;
}
.package-price sub {
  font-size: 50%;
  margin: 0 10px;
  background-color: transparent;
  background-image: repeating-linear-gradient(
    150deg,
    transparent 0%,
    transparent 46%,
    red 47%,
    red 50%,
    red 53%,
    transparent 54%,
    transparent 100%
  );
}
.package-duration {
  margin-bottom: 30px;
}

.package .action-btn {
  height: 44px;
  width: 148.02px;
  border-radius: 4.8px;
  background-color: #f6580f;
  box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.22);
  color: white;
  margin-bottom: 15px;
}

.package p {
  font-size: 12px;
  margin: 0px;
}
.package p:last-child {
  color: #f6580f;
}
.package p:last-child img {
  width: 25%;
  vertical-align: top;
}
